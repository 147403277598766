import React from "react"
import "../style/aurum.css"
import Layout from "../components/layout"
import "../style/tab.css"

class Aurum extends React.Component {
    render() {
        return (
            <Layout>
                <br /><br /><br /><br />
                {/* Aurum Slider */}
                <div id="carouselExampleIndicators" className="carousel slide z-9" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
			{/* <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>*/}
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100" src="../Images/Aurum_banner_sold_out.jpg" alt="First slide" />
                        </div>
			{/* <div className="carousel-item">
                            <img className="d-block w-100" src="../Images/a-sl.png" alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="../Images/a-sl.png" alt="Third slide" />
                        </div>*/}
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                {/* Aspire Aurum */}
                <img src="../Images/lr.png" alt="lr" className="l-aspire" />
                <div className="container pl-5 pr-5 ">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <img src="../Images/aurum.png" alt="aurum" className="img-aurum" />
                            <p className=" pb-5 mob-justify lh-2">Aspire Aurum by Sumadhura is a collection of real value homes in the heart of the IT boom belt in Bengaluru.  Aspire Aurum offers the benefits of a prime location, incredibly affordable prices and an enviable list of amenities that will put more expensive projects in the shade.  It is the perfect foundation for ambitious young Indians to put down their roots and prosper.</p>
                        </div>
                    </div>
                </div>
                {/* Floor Plan */}
                <div className="bg-aspiregray pt-4 pb-4 pl-5 pr-5 f-plan">
                    
            <img src="../Images/fplan.png" alt="Master Plan" class="w-100 f-plan" data-toggle="modal" data-target=".fplan"/>
                </div>
                {/* Tab Switch  */}
                <div className="bg-aspireblue pt-5 pb-3 pl-4 pr-4">
                    <div className="container bg-aspireblue">
                        <div class="bs-example">
                            <ul class="nav nav-tabs ">
                                <li class="nav-item">
                                    <a href="#floorplan" class="nav-link active" data-toggle="tab">FLOOR PLANS</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#images" class="nav-link fill-nav" data-toggle="tab">IMAGES</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#amenities" class="nav-link" data-toggle="tab">AMENITIES</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#spec" class="nav-link" data-toggle="tab">SPECIFICATIONS</a>
                                </li>
                                <li class="nav-item">
                                    <a href="#walk" class="nav-link" data-toggle="tab">WALKTHROUGH</a>
                                </li>
                            </ul>
                            <div class="tab-content pt-5 pb-5">
                                <div class="tab-pane fade show active" id="floorplan">
                                    <div className="row tab-1 text-justify">
                                        <div className="row pb-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f1.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f1" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f4.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f4" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f5.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f5" />
                                            </div>
                                        </div>
                                        <div className="row pb-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f8.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f8" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f2.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f2" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f3.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f3" />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f6.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f6" />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/AR_f7.jpg" style={{ width: "100%" }} data-toggle="modal" data-target=".f7" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="images">
                                    <div className="row tab-1 text-justify" >
                                        <div className="row">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i1.jpg" className="slide-img" data-toggle="modal" data-target=".i1" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i4.jpg" className="slide-img" data-toggle="modal" data-target=".i4" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i5.jpg" className="slide-img" data-toggle="modal" data-target=".i5" style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i6.jpg" className="slide-img" data-toggle="modal" data-target=".i6" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i7.jpg" className="slide-img" data-toggle="modal" data-target=".i7" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i8.jpg" className="slide-img" data-toggle="modal" data-target=".i8" style={{ width: "100%" }} />
                                            </div>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i9.jpg" className="slide-img" data-toggle="modal" data-target=".i9" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i3.jpg" className="slide-img" data-toggle="modal" data-target=".i3" style={{ width: "100%" }} />
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <img src="../Images/i2.jpg" className="slide-img" data-toggle="modal" data-target=".i2" style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="amenities">
                                    <div className="row tab-1 text-white pl-4 pr-4" id="tb3">
                                        <div className="row mt-4">
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am1.png" style={{ width: "80px" }} /> <span className="am-p">Lawn with Seating</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am2.png" style={{ width: "80px" }} /> <span className="am-p">Cycle Track and Pathway</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am3.png" style={{ width: "80px" }} /> <span>Kids Play Area</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am4.png" style={{ width: "80px" }} /> <span>Outdoor Gym with Tree Court</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am5.png" style={{ width: "80px" }} /> <span>Car Parking</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am6.png" style={{ width: "80px" }} /> <span>Skating Rink</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am7.png" style={{ width: "80px" }} /> <span>Multi-Purpose Court</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am8.png" style={{ width: "80px" }} /> <span>Hopscotch</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am9.png" style={{ width: "80px" }} /> <span>Swimming Pool</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am10.png" style={{ width: "80px" }} /> <span>Multi-Purpose Hall</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am11.png" style={{ width: "80px" }} /> <span>Indoor Games</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am12.png" style={{ width: "80px" }} /> <span>Yoga-Gym-Aerobics</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am13.png" style={{ width: "80px" }} /> <span>Meditation Pavilion</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am14.png" style={{ width: "80px" }} /> <span>Transformer Yard</span></p>
                                            </div>
                                            <div className="col-md-4 pb-3">
                                                <p><img src="../Images/am15.png" style={{ width: "80px" }} /> <span>Reflexology Pathway</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="spec">
                                    <div className="row tab-1 text-white" id="tb4">
                                        <div className="row mt-4 pb-4 ">
                                            <div className="col-md-6 text-justify ptsans-14 lh-2 mb-5">
                                                <p className="hs11 font-weight-bold"> STRUCTURE : </p>
                                                • R.C.C. framed structure to withstand wind & seismic loads as per IS code. Solid cement concrete block masonry 100mm, 150mm & 200mm.
                                                <br /><br /><br /><p className="hs11 font-weight-bold">DOORS :</p>
                                                • Main doors: Engineered hard wood frame with designer shutters of 35 mm thickness with melamine polish finished on both sides. • lnternal doors: Engineered hard wood frames with flush shutters of 32 mm thickness with enamel paint finished on both sides. • French doors: UPVC door systems with sliding shutters with prov1s1on for mosquito mesh. • Windows: UPVC window systems with safety grill (M.S) and prov1s1on for mosquito mesh & Aluminum for Toilet ventilators & Staircase windows. • All Hardware of reputed make.
                                                <br /><br /> <br /> <p className="hs11">WINDOWS :</p>
                                                • UPVC window systems with safety grills and provision for mosquito mesh
                                                <br /><br /><br /><p className="hs11 font-weight-bold">PAINTING :</p>
                                                • External: One coat primer and two coats of external emulsion paint. • Internal: Smooth putty finish with two coats of Tractor emulsion paint for walls and O.B.D paint for ceiling over a coat of primer.
                                                <br /><br /><br /><p className="hs11 font-weight-bold">FLOORING/ DADO :</p>
                                                • Staircases: Granite flooring. • Living, Dining, all Bed rooms, Kitchen & Corridor: 6 0 0 X 6 0 0 mm size Nano vitrified tiles. • Bathrooms: 300x300mm Satin finish ceramic tile flooring. • All Balconies/Utilities: 300x300mm Rustic ceramic tiles. • Dadoing in Kitchen: 300x450mm Glazed ceramic tiles dado up to 2' height above kitchen platform level. • Bathrooms: 300x450 Glazed ceramic tile dado up to False-Ceiling level. • Basement: cement concrete power troweled with smooth finish.
                                                <br /><br /><br /><p className="hs11 font-weight-bold"> KITCHEN :</p>
                                                • Provision for softened water outlet.
                                                <br /><br /> <br /><p className="hs11 font-weight-bold">BATHROOMS :</p>
                                                • Pedestal wash basin. • Floor mounted EWC. • Sanitary: Cera or Hindware or Equivalent. • C.P Fittings: Jaguar or Cera or Equivalent.
                                                <br /><br /> <br /><p className="hs11 "> ELECTRICAL :</p>
                                                • Concealed copper wiring of Polycab or Anchor or Equivalent. • Modular switches: Schneider or Anchor or Equivalent. • Power outlets for air conditioners in Master Bedroom. • Power outlets for geysers and Exhaust Fans in all bathrooms. • Power outlets in kitchen for Hob, chimney, refrigerator, microwave oven, mixer and washing machine in utility area. • 100 % DG backup power for Lifts, Pumps & lighting in common areas.
                                               </div>
                                            <div className="col-md-6 text-justify ptsans-14 lh-2">
                                                <p className="hs11 font-weight-bold"> PLUMBING :</p>
                                                • Drainage/sewage: PVC pipes & fittings • Water supply (Int & Ext): Cpvc or Upvc pipes & fittings.
                                                <br /><br /><br /> <p className="hs11 font-weight-bold"> TELECOM/ INTERNET/ CABLE TV :</p>
                                                • Provision for internet, DTH, telephone & intercom.
                                                <br /><br /><br /><p className="hs11 font-weight-bold">SECURITY :</p>
                                                • Round-the-clock security system. • Intercom facility to all apartments connecting to security room. • Panic button and intercom is provided in the lifts. • Solar power fencing around the compound. • Surveillance cameras at the main security and entrance of each Wing.
                                                <br /><br /><br /><p className="hs11 font-weight-bold"> LIFTS :</p>
                                                • One Eight passenger & one thirteen passenger Lift for Each Wing with auto rescue device with V3F for energy efficiency. (Schindler or Johnson or Equivalent make)
                                                <br /><br /><br /><p className="hs11 font-weight-bold"> WTP & STP :</p>
                                                • Softened water made available through an exclusive water treatment plant (in case of bore water). • Sewage treatment plant of adequate capacity as per norms will be provided inside the project, treated sewage water will be used for the landscaping /flushing purpose.
                                                <br /><br /><br /><p className="hs11 font-weight-bold"> CLUB HOUSE AMENITIES :</p>
                                                • Ground floor: Entrance Lobby, Multipurpose Hall, Party lawn, Adult pool, Kids pool, pool deck area, Change rooms & Carom/Chess/Foose ball/ Air Hockey. • First floor: Gymnasium, Yoga & Meditation, Aerobics
                                                <br /><br /><br /><p className="hs11 font-weight-bold">OPEN AREA AMENITIES :</p>
                                                • Security kiosk, Wing entry, Tree court with seating lawn Jogging track. Cycle track, Visitor parking, skating rink, Dribble court, Elder zone with seating, sand pit with children's play area, synthetic mat with children's play equipment. Amphitheater Stage with feature wall, Cycle stand, Outdoor library with seating, Drop off zone, Reflexology path, Hopscotch, Skating park, Pavilion with seating & pergola & tree grove.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="walk">
                                    <div className="row tab-1 text-center" id="tb4">
                                        <iframe width="100%" height="420" src="https://www.youtube.com/embed/tFN-jlGM1NI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* Aurum Advantage */}
                <div className="row blue-blur">
                    <div className="col-md-12">
                        <img src="../Images/blur-bg.jpg" alt="group" className="w-100" />
                    </div>
                </div>
                <div className="aspire-red"></div>
                {/* Real Value */}
                <img src="../Images/lr.png" alt="lr" className="l-aspire" />
                <div className="container pl-5 pr-5 ">
                    <div className="row">
                        <div className="col-md-12 text-center pb-5 pt-5">
                            <h2 className="aspireblue text-center text-Montserrat-2 pb-4">REAL PROXIMITY. REAL VALUE.</h2>
                            <p className="mob-justify pb-3 lh-2">Aspire Aurum is superbly located in the heart of Whitefield. Just minutes from everything you need, from work to entertainment to education and healthcare. A stone’s throw from EPIP and the gates of ITPB, Aspire Aurum is close to malls, entertainment, schools, hospitals and more. Aspire Aurum has been created to help you enjoy life more by commuting less! And when you need to commute, Aspire Aurum’s connectivity, including proximity to the upcoming Metro line, makes it truly effortless.</p>
                            <img src="../Images/Aurum_NEIGHBOURHOOD.jpg" alt="Floor Plan" className="w-100 f-plan" data-toggle="modal" data-target=".bd-example-modal-lg" />
                        </div>
                    </div>
                </div>
                {/* Real Life Style */}
                <div className="bg-aspiregray">
                    <div className="container pl-5 pr-5 ">
                        <div className="row">
                            <div className="col-md-12 text-center pb-4 pt-4">
                                <h2 className="aspireblue text-center text-Montserrat-2 pt-5">REAL LIFESTYLE. REAL VALUE.</h2>
                                <p className="mob-justify pt-3 pb-5 lh-2">Aspire Aurum envisions a coming together of like-minded people living in harmony in a comfortable gated community. After all, you want your home to be a haven that you come back to, and it is only natural to want a social and emotional connect with your neighbours. And when that connect is built on a foundation of safety and security, it becomes a space where everyone - kids, working individuals, elders can call their own.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Real benefits */}
                <img src="../Images/lt.png" alt="lr" className="lt-aspire" />
                <div className="container pl-5 pr-5 ">
                    <div className="row">
                        <div className="col-md-12 text-center pb-4 pt-4">
                            <h2 className="aspireblue text-center text-Montserrat-2 pt-5">REAL BENEFITS. REAL VALUE.</h2>
                        </div>
                    </div>
                    <div className="row pb-5">
                        <div className="col-md-3 hover-blue pb-3">
                            <img src="../Images/pe.png" alt="Percentage" style={{ width: "100px" }} className="pr-4" />
                            <p style={{ display: "inline" }}>Honest Pricing</p>
                        </div>
                        <div className="col-md-3 hover-blue pb-3">
                            <img src="../Images/lo.png" alt="Location" style={{ width: "100px" }} className="pr-4" />
                            <p style={{ display: "inline" }}>Location Advantage</p>
                        </div>
                        <div className="col-md-3 hover-blue pb-3">
                            <img src="../Images/pl.png" alt="Construction" style={{ width: "100px" }} className="pr-4" />
                            <p style={{ display: "inline" }}>Construction Quality</p>
                        </div>
                        <div className="col-md-3 hover-blue pb-3">
                            <img src="../Images/cl.png" alt="On Time" style={{ width: "100px" }} className="pr-4" />
                            <p style={{ display: "inline" }} className>On-Time Delivery</p>
                        </div>
                    </div>
                </div>
                {/* Amenities */}
                <div className=" bg-aspireblue">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center pb-4 pt-4 pl-5 pr-5">
                                <h2 className="text-white text-center text-Montserrat-2 pt-5">REAL AMENITIES. REAL VALUE.</h2>
                                <div className="row pt-5">
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px" }}>
                                            <img class="card-img-top" src="../Images/1a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Club house and Basketball Court</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px" }}>
                                            <img class="card-img-top" src="../Images/2a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Swimming Pool</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px" }}>
                                            <img class="card-img-top" src="../Images/3a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Gym</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <div class="card" style= {{ height:"225px" }}>
                                            <img class="card-img-top" src="../Images/4a.png" alt="Card image" />
                                            <div class="card-body-cus">
                                                <p class="card-text font-weight-bold">Multi-purpose Hall</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className=" text-white mob-justify pt-3 pb-2 lh-2">Aspire Aurum is packed with amenities. A plush Clubhouse with swimming pools, facilities for indoor sports and games, a gymnasium, yoga room and more. The sprawling open areas feature a tree court with seating lawn, jogging track, skating rink, amphitheatre with feature wall, elder zone for seniors, and even a reflexology path.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aspire-red"></div>

                {/* Approvals */}
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-md-12  pb-4 pt-4">
                            <h2 className="aspireblue text-left text-Montserrat-2 pt-5" style={{ display: "inline" }}>LOCATION MAP</h2>
                        </div>
                        <a href="https://goo.gl/maps/z1EiScABLskMVBwa8" target="_blank"><img src="../Images/map-aurum.png" alt="map" className="w-100 pt-3 pb-5" /></a>
                    </div>
                </div>
                <div className="aspire-red"></div>

                <div className="container pt-5 pb-5 pl-5 pr-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="aspireblue text-center text-Montserrat-2 pb-4">APPROVALS.</h2>
                        </div>
                    </div>
                    <div className="row" style={{ overflow: "auto !important" }}>
                        <table class="table table-striped" >
                            <thead>
                                <tr>
                                    <th scope="col">Sl No.</th>
                                    <th scope="col">List Of Approval</th>
                                    <th scope="col">Sanction Authority</th>
                                    <th scope="col">Date of Approval</th>
                                    <th scope="col">Approval No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>BDA Approval</td>
                                    <td>Bangalore Development Authority</td>
                                    <td>01/01/2020</td>
                                    <td>No.BDA/PS/EM/EO-3/TA-4/East/43/2019-20</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Water Permission -PDO</td>
                                    <td>Panchayath Water Permission Office</td>
                                    <td>18/09/2019</td>
                                    <td>PDO/Doddabanahalli/01/2019-20.</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>KSPCB</td>
                                    <td>Karnataka State Pollution Control Board</td>
                                    <td>18/06/2020	</td>
                                    <td>Consent Order No.CTE-318413</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>SEIAA</td>
                                    <td>State Level Environment Impact Assessment Authority-Karnataka</td>
                                    <td>13/12/2019</td>
                                    <td>SEIAA/136/CON/2019</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>FIRE</td>
                                    <td>Karnataka State Fire and Emergency Services</td>
                                    <td>06/01/2020</td>
                                    <td>No.KSFES/NOC/274/2019</td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>BSNL</td>
                                    <td>Bharat Sanchar Nigam Limited</td>
                                    <td>16/10/2019</td>
                                    <td>No. DE/SAN/BG/NOC-HRB (S-11)/2019-20/43 dated @BG-41</td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>AAI</td>
                                    <td>Airport Authority of India</td>
                                    <td>01/05/2020	</td>
                                    <td>BIAL/SOUTH/B/031420/451823</td>
                                </tr>
                                <tr>
                                    <th scope="row">8</th>
                                    <td>BESCOM</td>
                                    <td>Bangalore Electrical Supply and Company</td>
                                    <td>10/12/2019</td>
                                    <td>No.SEE/BRC/EE(O)/AEE(0)/F-lA/4697-98/2019-20</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* COMPLIANCE */}
                <div className="aspire-red"></div>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-md-12  pb-4 pt-4">
                            <h2 className="aspireblue text-center text-Montserrat-2 pb-4" >COMPLIANCE</h2>
                        </div>
                    </div>
                </div>
                <div className="container pb-5">
                    <div className="row">
                        <div class="col-md-4" style={{ textAlign: "center", fontWeight: "bold" }}><object className="doc-hide" data="../Compliance/Aspire_Aurum_Complainace_Doc_Dec_2020.pdf" width="100%" height="400"></object> June 2020
                    <br /><br /><a href="../Compliance/Aspire_Aurum_Complainace_Doc_Dec_2020.pdf" target="_blank"><img src="../Images/pdf.png" className="pb-5" /></a>
                        </div>
                        <div class="col-md-4" style={{ textAlign: "center", fontWeight: "bold" }}><object className="doc-hide" data="../Compliance/Aspire_Aurum_Complainace_Doc_June_2020.pdf" width="100%" height="400"></object> Dec 2020
                    <br /><br /><a href="../Compliance/Aspire_Aurum_Complainace_Doc_June_2020.pdf" target="_blank"><img src="../Images/pdf.png" className="pb-5" /></a>
                        </div>
                        <div class="col-md-4" style={{ textAlign: "center", fontWeight: "bold" }}><object className="doc-hide" data="../Compliance/Aspire-Aurum-Bangalore-Certificate.pdf" width="100%" height="400"></object> IGBC Doc
                    <br /><br /><a href="../Compliance/Aspire-Aurum-Bangalore-Certificate.pdf" target="_blank"><img src="../Images/pdf.png" className="pb-5" /></a>
                        </div>
                    </div>
                </div>
                {/* Calculator */}
                <div className="bg-aspiregray pt-3 pb-5" id="cal">
                    <div className="container pl-5 pr-5 ">
                        <div className="row">
                            <div className="col-md-12 text-center pb-4 pt-4">
                                <h4 className="aspireblue text-center text-Montserrat-2">EMI CALCULATOR</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-inr" style={{ paddingLeft: "4px" }, { paddingRight: "4px" }} aria-hidden="true"></i></span><input type="number" placeholder="Property Price" className="input" id="emi_pp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-inr" style={{ paddingLeft: "4px" }, { paddingRight: "4px" }} aria-hidden="true"></i></span><input type="number" placeholder="Down Payment" className="input" id="emi_dp" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-calendar" aria-hidden="true"></i></span><input type="number" placeholder="Duration Year" className="input" id="emi_dy" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-3 mpb-3">
                                        <span className="sign"><i class="fa fa-percent" aria-hidden="true"></i></span><input type="number" placeholder="Interest Rate" className="input" id="emi_ir" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pb-1 w-100">
                                        <button className="aurum-btn mt-3 mb-5" id="emi_btn">CALCULATE INSTALMENT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                                Monthly Payment (EMI)
                        </div>
                            <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                                Total Interest Payable
                        </div>
                            <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                                Total of Payments (Principal + Interest)
                        </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-4 bg-white pt-3 pb-3" id="emi-in">
                                0/-
                        </div>
                            <div className="col-md-4 bg-white pt-3 pb-3" id="int-in">
                                0/-
                        </div>
                            <div className="col-md-4 bg-white pt-3 pb-3" id="tot-in">
                                0/-
                        </div>
                        </div>

                    </div>
                </div>
                <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/Aurum_NEIGHBOURHOOD.jpg" alt="Map" className="img-pop" />
                        </div>
                    </div>
                </div>
                <div class="modal fade fplan" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg fplan-modal">
                        <div class="modal-content">
                            <img src="../Images/fplan.png" alt="Map" />
                        </div>
                    </div>
                </div>

                {/* Floor Plan */}
                <div class="modal fade f1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f1.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade f2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f2.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f3" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f3.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f8" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f8.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f4" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f4.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f5" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f5.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f6" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f6.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <div class="modal fade f7" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/AR_f7.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                {/* Images */}

                <div class="modal fade i1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i1.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i2.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i3" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i3.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i4" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i4.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i5" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i5.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i6" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i6.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i7" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i7.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i8" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i8.jpg" alt="Map" />
                        </div>
                    </div>
                </div>

                <div class="modal fade i9" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/i9.jpg" alt="Map" />
                        </div>
                    </div>
                </div>
                <script src="../Scripts/aurum_cal.js"></script>
		<script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="28513697"></script>
            </Layout >
        )
    }
}

export default Aurum
